// Used when you want a specific selection option(s) to toggle show/hide of another div

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['toggleMe']

  connect() {
  }

  toggle(event) {
    this.revealOrHideInputs(event.target.value, document.querySelectorAll(".dynamic-on-select"))
  }


  revealOrHideInputs(selected_option, toggleables) {
    for (let toggleable of toggleables) {
      if (selected_option == toggleable.dataset.toggleWhenSelected) {
        toggleable.classList.remove("hidden");
        var input = toggleable.querySelector("input")
        if (input) input.focus();
      } else {
        toggleable.classList.add("hidden");
      }
    }
  }
}